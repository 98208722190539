import { CestaProductRevisionMoto } from './constants'
import { Product } from '../../common/context/reducers/cestaReducer'
import { CategoriaCesta, TipoProductoCesta } from './type/Cesta'

export const isProductRevisionMoto = (product: Product) => {
  return (
    !!product &&
    product.tipo_producto.toString().toLowerCase() === CestaProductRevisionMoto
  )
}

const isCestaType = (
  products: Product[],
  category?: CategoriaCesta,
  include?: TipoProductoCesta[],
  exclude?: TipoProductoCesta[]
) =>
  products.length > 0 &&
  products.every(
    (p) =>
      (!category || p.categoria === category) &&
      (!include || include.includes(p.tipo_producto)) &&
      (!exclude || !exclude.includes(p.tipo_producto))
  )

export const isCestaMoto = (products: Product[]) =>
  isCestaType(products, 'moto')
export const isCestaCoche = (products: Product[]) =>
  isCestaType(products, 'coche', undefined, ['bateria', 'aceite'])
export const isCestaBateria = (products: Product[]) =>
  isCestaType(products, undefined, ['bateria'])
export const isCestaAceite = (products: Product[]) =>
  isCestaType(products, undefined, ['aceite'])
export const isCestaRevisionMoto = (products: Product[]) =>
  isCestaType(products, 'moto', undefined, ['revision_moto'])
